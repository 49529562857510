<template>
  <div class="chat">
    <div class="title-box">
      <a href="/"
        ><font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />홈</a
      >
      <h2>캔디 채널</h2>
    </div>
    <b-card header="CANDiY 채널 바로가기" class="text-center chatcard">
      <b-card-text class="chatcard-text">


        <a href="javascript:addChannel()" class="kakaotalk">
          <span class="logo">
            <img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/kakaotalk_app_logo.png"
              alt="카카오톡"
            />
          </span>
          <span>카카오톡</span>
        </a>



        <a href="https://lin.ee/LlL6YfA" class="line">
          <span class="logo">
            <img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Line_app_logo.png"
              alt="라인"
            />
          </span>
          <span>LINE</span>
        </a>
        <a href="#" class="instagram"
          ><span class="logo"
            ><img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Instagram_app_White_logo.png"
              alt="인스타그램"/></span
          ><span>Instagram</span></a
        >
        <a href="#" class="facebook"
          ><span class="logo"
            ><img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Facebook_app_gradient_logo.png"
              alt="페이스북 메신저"/></span
          ><span>Messenger</span></a
        >
        <a href="#" class="telegram"
          ><span class="logo"
            ><img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Telegram_app_color_logo.png"
              alt="텔레그램"/></span
          ><span>텔레그램</span></a
        >
      </b-card-text>
    </b-card>
    <!-- <ChatUrl v-if="group.length > 0" :group="group" />
      <div v-else>
        <ChatSelector  />
        <ChatList v-if="channel != null" />
      </div> -->
  </div>
</template>




s

<script>
export default {
  name: "Chat",

  components: {
    // 'ChatList' :  () => import ( '@/components/chat/ChatList' ),
    // 'ChatSelector' :  () => import ( '@/components/chat/ChatSelector' ),
    // 'ChatUrl' :  () => import ( '@/components/chat/ChatUrl' ),
  },
  data: function() {
    return {
      group: "",
    };
  },
  created() {
    if (this.$route.params.group != undefined) {
      this.group = this.$route.params.group;
    }
  },
  computed: {
    channel: {
      get: function() {
        return this.$store.state.chat.channel;
      },
      set: function() {},
    },
  },
};
</script>
<style>
.chat {
  text-align: center;
  width: 100%;
  padding-bottom: 30px;
}
.title-box {
  justify-content: space-between;
  margin: 2.7778vw auto 5.5556vw;
  height: 25px;
  position: relative;
  width: 93%;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 18px;
  transform: translateY(-50%);
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}

.chatcard {
  width: 85%;
  margin: 40px auto;
  padding-top: 10px;
}

.card {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  border: none;
  margin-bottom: 16px;
  max-width: 400px;
}
.card-header {
  padding: 0.75rem 1.25rem 0.2rem;
  margin-bottom: 0.1rem;
  background-color: transparent;
  border-bottom: none;
  font-weight: 800;
  font-size: 18px;
}

.chatcard .chatcard-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.chatcard .chatcard-text a {
  display: block;
  width: 95%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;
  color: #191919;
  display: flex;
  align-items: center;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.chatcard .chatcard-text a span {
  vertical-align: middle;
  display: block;
}
.chatcard .chatcard-text a span.logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  overflow: hidden;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1rem;
}
.chatcard .chatcard-text a span img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.chatcard .chatcard-text a.kakaotalk {
  background-color: #fee500;
}
.chatcard .chatcard-text a.line {
  background-color: #06c755;
  color: #fff;
}
.chatcard .chatcard-text a.instagram {
  background: linear-gradient(
    87.1deg,
    #ffd522 -10.92%,
    #f1000b 48.02%,
    #b900b3 106.81%
  );
  color: #fff;
}
.chatcard .chatcard-text a.facebook {
  background: -webkit-linear-gradient(
    45deg,
    #0a7cff 0%,
    #a10eeb 60%,
    #ff5297 93%,
    #ff6c5c 100%,
    #7db9e8 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.chatcard .chatcard-text a.telegram {
  background-color: #fbfbff;
}
</style>
